// FieldDataParser Dev
import { useEffect, useMemo, useState } from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import { FieldAttributes } from "types"
import { InputSwitch } from "components/InputSwitch"
import {
  determineIsRequired,
  determineShouldPrefill,
  determineShowField,
  getConditionalFieldsToWatch,
} from "utilities/conditions"
import { useWatch, useFormContext } from "react-hook-form"
import { get } from "lodash"

interface FieldDataParserProps extends BoxProps {
  fieldData: FieldAttributes
  nestedIndex?: number
  isRequiredErrors?: boolean
  controlSectionName?: string
  isFinalCheck?: boolean
}

/**
 * A component for generating a field given the field data in JSON format
 *
 * @param fieldData The JSON representing the field information
 * @returns The component representation of the field data
 */
export const FieldDataParser = ({
  fieldData,
  nestedIndex,
  controlSectionName,
  isRequiredErrors,
  width,
  isFinalCheck,
  ...rest
}: FieldDataParserProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext()

  const [showField, setShowField] = useState<boolean>(false)
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>()
  const [hasPrefilled, setHasPrefilled] = useState<boolean>(false)
  const [label, setLabel] = useState<string>(fieldData.label)

  const fieldName =
    controlSectionName && nestedIndex !== undefined
      ? `${controlSectionName}.${nestedIndex}.${fieldData.name}`
      : fieldData.name

  // get() will return undefined instead of throwing an error if part
  // of a path like `parent.0.firstName` doesn't exist.
  const fieldError = get(errors, fieldName)

  // Watch your field data, and the fields in your conditions
  const relevantFieldNames: string[] = useMemo(() => {
    return getConditionalFieldsToWatch(fieldData, fieldName, nestedIndex)
  }, [fieldData, fieldName, nestedIndex])

  const relevantFieldValues = useWatch({ control, name: relevantFieldNames })

  useEffect(() => {
    if (nestedIndex !== undefined) {
      setLabel(fieldData.label?.replaceAll("{{x}}", `${nestedIndex + 1}`))
    }
  }, [fieldData.label, nestedIndex])

  useEffect(() => {
    if (fieldError) {
      setIsInvalid(true)
      setErrorText(String(fieldError.message))
    } else {
      setIsInvalid(false)
      setErrorText(undefined)
    }
  }, [fieldError])

  useEffect(() => {
    const relevantFields = {}
    let allValues = getValues()

    if (controlSectionName && nestedIndex !== undefined) {
      allValues = get(allValues, `${controlSectionName}.${nestedIndex}`, {})
    }

    Object.keys(allValues).forEach(value => {
      if (relevantFieldNames.includes(value)) {
        relevantFields[value] = allValues[value]
      }
    })

    if (relevantFieldNames.length > 0) {
      const isShown = determineShowField(fieldData, relevantFields, nestedIndex)
      setShowField(isShown)
      setIsRequired(
        isShown && determineIsRequired(fieldData, relevantFields, nestedIndex),
      )

      /**
       When form values relevant to this field change, we must recalculate the following:
       - if the field is shown
       - if the field is required
       - if the field is prefilled with a value
       */
      const { shouldPrefill, prefillValue, shouldClear } =
        determineShouldPrefill(
          fieldData,
          hasPrefilled,
          relevantFields,
          nestedIndex,
        )

      if (shouldPrefill) {
        setHasPrefilled(true)
        setValue(fieldName, prefillValue)
      } else if (shouldClear) {
        setValue(fieldName, null)
        setHasPrefilled(false)
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    getValues(),
    fieldData,
    relevantFieldNames,
    relevantFieldValues,
    nestedIndex,
    controlSectionName,
    fieldName,
  ])

  useEffect(() => {
    const elements = document.querySelectorAll(".combined-fields-wrapper")

    elements.forEach((element: any) => {
      if (!element.innerHTML) {
        element.style.display = "none"
      } else {
        element.style.display = "flex"
      }
    })
  }, [showField])

  if (!showField) {
    return null
  }

  let parsedFieldData = fieldData

  if (nestedIndex !== undefined) {
    parsedFieldData.label = fieldData.label?.replaceAll(
      "{{x}}",
      `${nestedIndex + 1}`,
    )
  }

  return (
    <Box
      w={{ base: "100%", tab: "auto" }}
      flex={{ base: "1 1 0", tab: `1 1 ${width}` }}
      {...rest}
    >
      <InputSwitch
        control={control}
        fieldData={parsedFieldData}
        isRequired={isRequired}
        isInvalid={isInvalid}
        errorText={errorText}
        name={fieldName}
        nestedIndex={nestedIndex}
        label={label}
        isRequiredErrors={isRequiredErrors}
        isFinalCheck={isFinalCheck}
      />
    </Box>
  )
}
