import { Option, OptionData } from "types"

/**
 * Convert an array of strings or tuples to an array of objects with label and value keys
 * @example optionize(["Yes", "No"])
 * => [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]
 * @example optionize([["Yes, I agree.", "Yes"], ["No, I disagree.", "No"]])
 * => [{ label: "Yes, I agree.", value: "Yes" }, { label: "No, I disagree.", value: "No" }]
 */
export const optionize = (arr: OptionData): Option[] =>
  arr.map(item =>
    Array.isArray(item)
      ? { label: item[0], value: item[1] }
      : { label: item, value: item },
  )
