import { colors } from "themes/hollins/colors"

const base64Checkmark =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb25zL2NoZWNrIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTUuOTk5OTggMTAuNzhMMy4yMTk5OCA4LjAwMDAyTDIuMjczMzIgOC45NDAwMkw1Ljk5OTk4IDEyLjY2NjdMMTQgNC42NjY2OEwxMy4wNiAzLjcyNjY4TDUuOTk5OTggMTAuNzhaIiBmaWxsPSIjMzczNzM0Ii8+CjwvZz4KPC9zdmc+Cg=="

const base64MagnifyingGlass =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTIuOTE2NyAxMS42NjY3SDEyLjI1ODNMMTIuMDI1IDExLjQ0MTdDMTIuODQxNyAxMC40OTE3IDEzLjMzMzMgOS4yNTgzMyAxMy4zMzMzIDcuOTE2NjdDMTMuMzMzMyA0LjkyNSAxMC45MDgzIDIuNSA3LjkxNjY3IDIuNUM0LjkyNSAyLjUgMi41IDQuOTI1IDIuNSA3LjkxNjY3QzIuNSAxMC45MDgzIDQuOTI1IDEzLjMzMzMgNy45MTY2NyAxMy4zMzMzQzkuMjU4MzMgMTMuMzMzMyAxMC40OTE3IDEyLjg0MTcgMTEuNDQxNyAxMi4wMjVMMTEuNjY2NyAxMi4yNTgzVjEyLjkxNjdMMTUuODMzMyAxNy4wNzVMMTcuMDc1IDE1LjgzMzNMMTIuOTE2NyAxMS42NjY3Wk03LjkxNjY3IDExLjY2NjdDNS44NDE2NyAxMS42NjY3IDQuMTY2NjcgOS45OTE2NyA0LjE2NjY3IDcuOTE2NjdDNC4xNjY2NyA1Ljg0MTY3IDUuODQxNjcgNC4xNjY2NyA3LjkxNjY3IDQuMTY2NjdDOS45OTE2NyA0LjE2NjY3IDExLjY2NjcgNS44NDE2NyAxMS42NjY3IDcuOTE2NjdDMTEuNjY2NyA5Ljk5MTY3IDkuOTkxNjcgMTEuNjY2NyA3LjkxNjY3IDExLjY2NjdaIiBmaWxsPSIjMzczNzM0Ii8+Cjwvc3ZnPg=="

const Button = {
  baseStyle: {
    borderRadius: "999px",
    boxShadow: "none",
    fontFamily: "body",
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",

    _hover: {
      boxShadow: "none",
      _disabled: {
        boxShadow: "none",
      },
    },
    _focus: {
      boxShadow: "none",
    },
    _active: {
      boxShadow: "none",
    },
  },
  sizes: {
    sm: {
      fontSize: "sm",
      lineHeight: 1.57143,
      height: "auto",
      paddingX: 4,
      paddingY: 2,
    },
    md: {
      fontSize: "md",
      lineHeight: 1.5,
      height: "auto",
      paddingX: 5,
      paddingY: "0.625rem",
    },
  },
  variants: {
    solid: {
      background: "buttonLinkColor",
      boxShadow: "2px 2px 6px 0px rgba(0, 0, 0, 0.15)",
      color: "white",
      border: "none",
      outline: "2px solid",
      outlineColor: "buttonLinkColor",
      _hover: {
        background: "buttonLinkHoverColor",
        outlineColor: "buttonLinkHoverColor",
        _disabled: {
          background: "buttonLinkColor",
        },
      },
      _focus: {
        background: "buttonLinkFocusColor",
        outlineColor: "buttonLinkFocusBorderColor",
        boxShadow: "none",
      },
      _active: {
        background: "buttonLinkFocusColor",
        outlineColor: "buttonLinkFocusBorderColor",
        boxShadow: "none",
      },
      _disabled: {
        background: "buttonLinkDisabledColor",
        outlineColor: "buttonLinkDisabledColor",
      },
    },
    outline: {
      border: "none",
      outline: "2px solid",
      backgroundColor: "white",
      outlineColor: "buttonLinkColor",
      color: "buttonLinkColor",
      _hover: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[80],
        color: colors.accent[80],
        _disabled: {
          color: "buttonLinkDisabledColor",
          outlineColor: "buttonLinkDisabledColor",
        },
      },
      _focus: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[90],
        color: colors.accent[90],
      },
      _active: {
        backgroundColor: colors.accent[10],
        outlineColor: colors.accent[90],
        color: colors.accent[90],
      },
      _disabled: {
        color: "buttonLinkDisabledColor",
        outlineColor: "buttonLinkDisabledColor",
      },
    },
    ghost: {},
    link: {
      background: "transparent!",
      border: "none",
      outline: "none",
      borderRadius: "none",
      color: "buttonLinkColor",
      margin: 0,
      padding: 0,
      fontWeight: "semibold",
      _hover: {
        color: "buttonLinkHoverColor",
        _disabled: {
          color: "buttonLinkColor",
        },
      },
      focus: {
        color: "buttonLinkActiveColor",
      },
      _active: {
        color: "buttonLinkActiveColor",
      },
      _disabled: {
        color: "buttonLinkDisabledColor",
      },
    },
  },
}

/** This is the final check page */
const LayoutCentered = {
  baseStyle: {
    main: {
      maxWidth: "48.75rem",
      paddingY: { base: 4, md: 0 },

      ".chakra-accordion": {
        marginTop: 8,
        marginBottom: 0,
        zIndex: 1,

        "> .chakra-stack": {
          gap: {
            base: 0,
            md: 6,
          },
        },
      },
    },

    ".final-check__submit-button": {
      marginTop: {
        base: 8,
        md: 12,
      },
    },
  },
}

const cardBorderRadius = { base: 0, md: "4px" }
const FinalCheckCard = {
  baseStyle: {
    background: "white!",
    borderRadius: cardBorderRadius,
    borderBottom: `1px solid ${colors.neutral[40]}`,
    borderTop: `1px solid ${colors.neutral[40]}`,
    borderLeft: {
      base: "none",
      md: `1px solid ${colors.neutral[40]}`,
    },
    borderRight: {
      base: "none",
      md: `1px solid ${colors.neutral[40]}`,
    },

    boxShadow: {
      base: "none",
      md: "1px 2px 6px 0px rgba(39, 44, 51, 0.15)",
    },

    "&:last-of-type": {
      borderBottom: "1px solid",
      borderColor: "formInputBorderColor",
    },

    "&:has([aria-expanded='true'])": {
      borderColor: "formInputFocusedBorderColor",
    },

    "&:focus-within": {
      boxShadow: "outline",
      transform: "translateY(0)", // overlap hack
    },

    ".chakra-collapse": {
      overflow: "visible!important",

      "> div": {
        padding: { base: 8 },
      },
      ".combined-fields-wrapper": {
        gap: 6,
      },
    },
  },
}

/** button.chakra-accordion__button */
const FinalCheckCardHeader = {
  baseStyle: {
    background: "white",
    borderRadius: cardBorderRadius,
    gap: "0.625rem",
    padding: { base: 6 },
    _focus: {
      background: colors.neutral[20],
      boxShadow: "none",
    },

    _hover: {
      background: colors.neutral[20],
    },

    "&[aria-expanded='true']": {
      backgroundColor: colors.neutral[20],
    },

    "& > svg": {
      height: 8,
      width: 8,
    },

    ".card-header-eyebrow": {
      color: colors.neutral[50],
      fontWeight: 600,
      fontSize: "md",
      lineHeight: 1.5,
    },

    h2: {
      margin: 0,
    },

    ".error-badge": {
      backgroundColor: colors.negative[90],
      borderRadius: "1rem",
      boxShadow: "none",
      gap: "2px",
      marginRight: 0,
      paddingX: 3,

      "& svg": {
        height: "0.875rem",
        width: "0.875rem",
        padding: "1px",
      },

      "& span": {
        marginLeft: 0,
      },
    },

    ".success-badge": {
      backgroundColor: colors.primary[80],
      boxShadow: "none",
    },
  },
}

const Headings = {
  H1: {
    baseStyle: {
      fontFamily: "h1",
      fontSize: {
        base: "1.75rem",
        sm: "2rem",
        md: "3rem",
      },
      fontWeight: "bold",
      lineHeight: { base: 1.142, md: 1.3 },
      marginBottom: 1,
    },
  },
  H2: {
    baseStyle: {
      color: "bodyColor",
      fontSize: {
        base: "1.25rem",
        md: "1.625rem",
      },
      fontWeight: "bold",
      lineHeight: {
        base: 1.2,
        md: 1.23077,
      },
      marginBottom: {
        base: 1,
        md: 1,
      },
    },
  },
  H3: {
    baseStyle: {
      fontSize: { base: "xl", md: "xl!" },
      fontWeight: "500!",
      lineHeight: 1.2,
    },
  },
}

const Input = {
  baseStyle: {
    field: {
      height: {
        base: "auto",
      },
    },
  },
  sizes: {
    base: {
      field: {
        fontSize: "sm",
      },
    },
    md: {
      field: {
        fontSize: "md",
        height: 12,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: {
          base: "4px",
        },
        // repetition necessary to override base padding
        paddingX: {
          base: 3,
          sm: 3,
          md: 3,
        },
        paddingY: {
          base: 2,
        },
        borderColor: "formInputBorderColor",

        _placeholder: {
          color: colors.neutral[60],
        },

        _hover: {
          borderColor: "formInputHoveredBorderColor",
        },

        _focus: {
          borderColor: "formInputFocusedBorderColor",
          outline: "1px solid",
          outlineColor: "formInputFocusedBorderColor",
          outlineOffset: 0,
          boxShadow: "1px 2px 6px 0px rgba(39, 44, 51, 0.15)",
        },

        _invalid: {
          borderColor: colors.negative[70],
          boxShadow: "none",
          _focus: {
            borderColor: colors.negative[70],
            outline: `1px solid ${colors.negative[70]}`,
          },
        },

        _disabled: {
          background: "disabledBackground",
          borderColor: "disabledBorder",
          color: colors.neutral[50],
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
}

const Textarea = {
  variants: {
    outline: {
      ...Input.variants.outline.field,
    },
  },
  ...Input.defaultProps,
}

const Nav = {
  baseStyle: {
    a: {
      "& span": {
        color: "navLinkColor",
      },
      "&:hover span": {
        color: "navLinkHoverColor",
      },
      "&.active > span": {
        background: "navLinkHoverBackgroundColor",
        borderLeft: "4px solid",
        color: "navLinkHoverColor",
        "& *": {
          color: "inherit",
        },
      },
    },
    // This is the only place I can successfully apply this style from
    "+ main": {
      overflow: "visible",
    },
  },
}

const NestedSectionDataParser = {
  baseStyle: {
    border: "1px solid",
    borderColor: colors.primary[50],
    borderRadius: "none",
    padding: 6,

    h3: {
      fontWeight: "bold",
      fontSize: "lg",
    },
  },
}

const Text = {
  baseStyle: {
    color: "bodyColor",
    fontSize: {
      base: "1rem",
      md: "1.125rem",
    },
    lineHeight: {
      base: 1.5,
      md: 1.55556,
    },
  },
}

const PageHeader = {
  baseStyle: {
    padding: "0!",
    marginBottom: {
      base: 0,
      md: 0,
    },
  },
}

const Main = {
  baseStyle: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: {
        base: 8,
        md: 12,
      },
    },
  },
}

const ContentWrapper = {
  baseStyle: {
    "& > div": {
      border: "none",
    },
    ".radio-group, .checkbox-group": {
      gap: 1,
    },
  },
}

const selectionControlChecked = {
  background: colors.accent[80],
  borderColor: colors.accent[80],
}

const selectionControlStyles = {
  control: {
    borderColor: "formInputBorderColor",
    height: 5,
    width: 5,
    marginX: {
      base: "0.625rem",
    },
    marginY: {
      base: "calc(0.5rem + 1px)",
    },
    paddingY: {
      base: "1px",
    },
    _hover: {
      background: colors.accent[10],
      borderColor: selectionControlChecked.borderColor,
    },
  },
  label: {
    fontSize: { base: "1rem" },
    lineHeight: { base: 1.5 },
    _checked: {
      fontWeight: "600",
    },
  },
  defaultProps: {
    size: "sm",
  },
}

const Radio = {
  parts: ["control", "label"],
  sizes: {
    sm: {
      label: { ...selectionControlStyles.label, marginLeft: 0 },

      control: {
        ...selectionControlStyles.control,
        _checked: {
          borderColor: selectionControlChecked.borderColor,
          _before: {
            background: selectionControlChecked.background,
            height: "0.625rem",
            width: "0.625rem",
          },
          _hover: selectionControlStyles.control._hover,
        },
      },
    },
  },
  defaultProps: selectionControlStyles.defaultProps,
}

const Checkbox = {
  parts: ["control", "icon", "label"],
  sizes: {
    sm: {
      label: { ...selectionControlStyles.label, marginLeft: 0 },
      control: {
        ...selectionControlStyles.control,
        _checked: {
          ...selectionControlChecked,
          _hover: selectionControlChecked,
          _focus: {
            _hover: selectionControlChecked,
          },
        },
      },
    },
  },
  defaultProps: selectionControlStyles.defaultProps,
}

const SectionDataParser = {
  baseStyle: {
    borderBottomColor: "none",
    paddingX: { base: 4, md: 6 },
    paddingY: {
      base: 0,
    },
  },
}

const SiteFooter = {
  baseStyle: {
    backgroundColor: colors.neutral[10],
    borderTop: `4px solid ${colors.accent[80]}`,
    display: "flex",
    flexDirection: "column",
    fontSize: "xs",
    lineHeight: 1.5,
    marginTop: "auto",
    paddingTop: {
      base: 6
    },

    "> div": {
      position: "relative",
    },

    ".footer-container": {
      alignItems: {
        base: "center",
        md: "flex-start"
      },
      display: "flex",
      flexDirection: {
        base: "column",
      },
      flexWrap: {
        md: "wrap"
      },
      gap: { base: 3 },
      marginX: {
        base: "auto",
        md: 0,
      },
      maxWidth: {
        md: "40ch"
      },
      width: "fit-content",
      textAlign: {
        base: "center",
        md: "left"
      },
    },

    ".contact-details": {
      display: "flex",
      gap: 6,
      justifyContent: "center",

      a: {
        color: "bodyColor",
        textDecoration: {
          base: "none",
        },
        _hover: { color: "buttonLinkHoverColor" },
      },

      svg: {
        color: colors.accent[80],
        height: { base: 3, lg: 4 },
        width: { base: 3, lg: 4 },
      },

      ".phone-icon, .email-icon": {
        display: "inline",
      },
    },

    ".privacy-policy": {
      textDecoration: "underline",
    },

    "#user-signout": {
      alignItems: {
        base: "center",
        md: "flex-end"
      },
      gap: {
        base: 2
      },
      marginTop: {
        base: 3,
        md: 0
      },
      position: {
        md: "absolute",
      },
      right: {
        md: 6,
      },

      p: {
        marginTop: 0,

        span: {
          color: "buttonLinkColor",
          cursor: "not-allowed",
          fontSize: "inherit",
          fontWeight: 600,
          textDecoration: "underline",
        },
      },
    },

    ".logo": {
      display: "none",
    },
  },
}

const SiteHeader = {
  baseStyle: {
    backgroundColor: "transparent",
    paddingTop: { base: 4, md: 6 },
    paddingBottom: { base: 4, md: "1.125rem" },
    marginBottom: { base: 0, md: "4.25rem" },

    "& .logo": {
      height: "auto",
      width: {
        base: "155px",
        md: "152px",
      },
    },
  },
}

const SelectMenuNav = {
  baseStyle: {
    background: "white",

    "&#mobile-nav": {
      paddingX: "2.375rem",
      paddingTop: 4,
      paddingBottom: 8,

      select: {
        background: colors.primary[80],
        color: "white",
        fontWeight: "bold",
        fontSize: "1rem",
        lineHeight: "1.5",
        height: "auto",
        paddingLeft: 4,
        paddingRight: 3,
        paddingY: 2,
        borderRadius: "4px",
        border: "none",
      },
      "& .chakra-select__icon-wrapper": {
        color: "white",
      },
    },
  },
}

const IconButton = {
  variants: {
    link: {
      ...Button.variants.link,
    },
  },
  sizes: {
    sm: {
      height: 8,
      width: 4,
    },
  },
  defaultProps: {
    size: "sm",
    variant: "link",
  },
}

// ------------------------------------------------------------------------------

// https://github.com/bl00mber/react-phone-input-2
const { field: outlineInputStyles } = Input.variants.outline
const { sizes: inputSizes } = Input

const invalidInputStyles = {
  ...outlineInputStyles._invalid,
  background: "inherit",
  _focus: {
    ...outlineInputStyles._invalid._focus,
    boxShadow: outlineInputStyles._focus.boxShadow,
  },
  _hover: {
    borderColor: colors.negative[70],
  },
}

const PhoneInput = {
  baseStyle: {
    ".react-tel-input": {
      fontFamily: "body",
      fontSize: {
        base: inputSizes.base.field.fontSize,
        md: inputSizes.md.field.fontSize,
      },

      "&:has(+ [class*='error-message'])": {
        "> .form-control": invalidInputStyles,
      },

      ".form-control": {
        ...outlineInputStyles,
        fontSize: "inherit",
        height: inputSizes.md.field.height,
        width: "100%",
        // repeat styles necessary to override base padding
        paddingLeft: { base: "3.75rem", sm: "3.75rem", md: "3.75rem" },

        "&.invalid-number": invalidInputStyles,
      },

      ".flag-dropdown": {
        background: "transparent!",
        border: "none",

        "&.open": {
          width: "100%",
          position: "relative",
        },

        ".selected-flag": {
          background: "inherit",
          paddingLeft: outlineInputStyles.paddingX,
          "&.open": {
            transform: "translateY(-1.75rem)",
          },
        },

        "ul.country-list": {
          position: "relative",
          width: "100%",
          marginTop: 1,
          padding: 1,
          paddingTop: 0,
          borderRadius: outlineInputStyles.borderRadius,
          border: "1px solid",
          borderColor: colors.neutral[40],
          boxShadow: "0px 2px 8px 0px rgba(50, 59, 72, 0.10)",
          maxHeight: "21rem", // 7 items
          scrollSnapType: "y mandatory",

          "li.search": {
            display: "flex",
            paddingX: 2,
            paddingY: 0,
            alignItems: "center",
            borderBottom: "1px solid",
            borderBottomColor: colors.neutral[30],

            "&::before": {
              content: `url(${base64MagnifyingGlass})`,
              lineHeight: 1,
              height: 5,
              width: 5,
            },

            ".search-box": {
              border: "none",
              fontSize: "md",
              lineHeight: 1.5,
              marginLeft: 1,
              paddingY: 2,
              textTransform: "capitalize",
              width: "100%",
            },
          },

          "li.country": {
            display: "flex",
            alignItems: "center",
            marginTop: "0!",
            paddingX: 3,
            paddingY: 2,
            lineHeight: 1,
            fontSize: "md",
            scrollSnapAlign: "end",
            gap: 1,

            "&:hover": {
              backgroundColor: `${colors.accent[10]}!`,
            },

            "&[aria-selected=true]": {
              backgroundColor: "white",

              ".country-name, .dial-code": {
                fontWeight: "bold",
                color: "bodyColor!",
              },

              "&::after": {
                content: `url(${base64Checkmark})`,
                lineHeight: 1,
                position: "absolute",
                right: 3,
              },
            },

            ".flag": {
              display: "block",
              position: "relative",
              margin: 0,
              top: "auto",
              left: "auto",
              height: "25px",
            },
          },
        },
      },
    },
  },
}

const MultiSelect = {
  baseStyle: {
    menu: provided => ({
      ...provided,
      boxShadow: "md",
    }),
    menuList: provided => ({
      ...provided,
      paddingY: 0,
      "&::-webkit-scrollbar": {
        width: "14px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset  0 6px white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.neutral[30],
        borderRadius: "7px",
        height: "33%",
        maxHeight: "100px",
        border: "4px solid transparent",
        backgroundClip: "padding-box",
      },
    }),
    option: (provided, state) => {
      if (state.children === "United States") {
        console.log(state)
      }
      return {
        ...provided,

        background: "white!",
        color: "formInputTextColor!",
        fontWeight: state.isSelected ? "bold" : "normal",
        justifyContent: "space-between",
        lineHeight: 1.5,
        paddingX: 3,
        paddingY: 2,

        "&::after": {
          content: state.isSelected ? `url(${base64Checkmark})` : "''",
          lineHeight: 1,
        },
        "&:hover": {
          backgroundColor: `${colors.accent[10]}!`,
          color: "formInputTextColor",
        },
      }
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        backgroundColor: "transparent",
        borderRadius: "md",
        color: state.isDisabled ? colors.neutral[50] : "bodyColor",
        padding: 0,
        svg: {
          width: 8,
          height: 8,
          padding: 1,
        },
        width: "auto",
        height: "auto",
      }
    },
    indicatorSeparator: (provided, state) => {
      const showSeparator = state.hasValue && !state.isDisabled
      return {
        ...provided,
        display: showSeparator ? null : "none!",
        marginX: 2,
      }
    },
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    clearIndicator: provided => ({
      ...provided,
      margin: 0,
    }),
  },
}

const HelperText = {
  baseStyle: {
    color: "bodyColorLight",
    fontSize: "0.75rem",
    lineHeight: { base: 1.5, md: 1.29, lg: 1.5 },
  },
}

const FormLabel = {
  baseStyle: {
    color: "formLabelColor",
    fontWeight: "bold",
    fontSize: {
      base: "sm",
      md: "md",
    },
    lineHeight: 1.5,
    span: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
}

const OptionalTag = {
  baseStyle: {
    color: "bodyColorLight",
    fontSize: "0.75rem",
    lineHeight: 1.5,
  },
}

export const appComponents = {
  Button,
  ContentWrapper,
  Checkbox,
  FinalCheckCard,
  FinalCheckCardHeader,
  ...Headings,
  Input,
  LayoutCentered,
  Main,
  Nav,
  NestedSectionDataParser,
  NumberInput: {
    ...Input,
  },
  OptionalTag,
  PageHeader,
  Radio,
  SectionDataParser,
  SelectMenuNav,
  SiteFooter,
  SiteHeader,
  Text,
  Textarea,
  IconButton,
  PhoneInput,
  MultiSelect,
  HelperText,
  FormLabel,
}
