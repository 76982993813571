import { ApplicationData } from "types"
import {
  SIENA_MAJORS,
  SIENA_MAJORS_HEOP,
  SIENA_BUSINESS_CONCENTRATIONS,
} from "./"
import { optionize } from "data/utils"
import { SIENA_BIOLOGY, SIENA_NURSING } from "./siena_majors"
import {
  NON_US_CITIZEN_APP_OPTIONS,
  HEOP_ELIGIBLE_APP_OPTIONS,
  STANDARD_APP_OPTIONS,
} from "./siena_admission_types"

export const SIENA_DATA: ApplicationData = {
  layout: {
    version: "1",
  },
  meta: {
    title: "Siena College",
    contact: {
      email: "admissions@siena.edu",
      phone: "518-783-2423",
      website: "https://www.siena.edu/privacy/",
    },
    logo: "https://www.sienacollege.org/collegetips/sophomore-w/assets/6055ba2b-d12b-4347-ad55-13367b1ec614%2FSienaLogo.svg",
    footerLogo:
      "https://www.sienacollege.org/collegetips/sophomore-w/assets/6055ba2b-d12b-4347-ad55-13367b1ec614%2FSienaFooterLogo.svg",
    favicon: "https://www.siena.edu/siena_favicon.png",
    name: "Siena College",
    submissionRedirectUrl: "https://sienacollege.org/application/submitted",
  },
  susiPages: {
    signIn: {
      heading: "Sign In",
      htmlContent:
        '<p>Enter your email address and password below to continue filling out your Siena Application. Need to start a brand new application? <a class="userflow">Sign up now.</a></p>',
    },
    signUp: {
      heading: "Here's your Siena Express Application",
      subheading:
        "This application is free and takes less than 10 minutes to complete.",
      htmlContent:
        '<section><p><strong>Plus, Siena guarantees:</strong></p><ul><li>At least $57,000 in scholarships toward your degree</li><li>Graduate in 4 years, or Siena pays for the rest</li><li>Personalized career experience from the #1 college in NY for getting a job</li></ul><p>Enter your email address and create a password to begin filling out your Siena Express Application, or <a class="userflow">continue an application you started</a>.</p></section>',
    },
    forgotPassword: {
      heading: "Passphrase Reset",
    },
  },
  pages: [
    {
      id: "about-you",
      slug: "about-you",
      title: "About You",
      navTitle: "About You",
      description: "We'd like to get to know you better.",
      sections: [
        {
          title: "You",
          description: "",
          fields: [
            {
              name: "first_name",
              type: "text",
              label: "First/Given Name",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "middle_name",
              type: "text",
              label: "Middle Name",
              group: 1,
              order: 2,
            },
            {
              name: "last_name",
              type: "text",
              label: "Last/Family/Surname",
              isRequired: true,
              group: 1,
              order: 3,
            },
            {
              name: "suffix",
              type: "text",
              label: "Suffix",
            },
            {
              name: "preferred_name",
              type: "text",
              label: "Preferred First Name",
              helperText:
                "What first name do you want us to use in all communication to you and your family?",
            },
            {
              name: "date_of_birth",
              type: "date",
              label: "Date of Birth",
              isRequired: true,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
            },
            {
              name: "sex",
              type: "radio",
              label: "Legal Sex",
              options: optionize(["Male", "Female", "X or another legal sex"]),
              isRequired: true,
            },
            {
              name: "gender_identity",
              type: "checkbox",
              label: "Gender",
              helperText: "Your personal sense of identity.",
              options: optionize([
                "Female",
                "Male",
                "Nonbinary",
                "Add another gender",
              ]),
            },
            {
              name: "gender_identity_other",
              type: "text",
              label: "My gender",
              show: {
                conditions: [
                  {
                    name: "gender_identity",
                    value: "Add another gender",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "gender_identity",
                    value: "Add another gender",
                  },
                ],
              },
            },
            {
              name: "preferred_pronouns",
              type: "radio",
              label: "Pronouns",
              options: optionize([
                "He / Him / His",
                "She / Her / Hers",
                "They / Them / Theirs",
                "Other",
              ]),
            },
          ],
        },
        {
          title: "Your Background",
          description: "We'd like to get to know you better.",
          fields: [
            {
              name: "hispanic",
              label: "Are you Hispanic or Latino/a/x?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "race",
              label: "Your Race",
              helperText:
                "No matter how you answer the question above, please let us know how you describe yourself.",
              type: "checkbox",
              options: optionize([
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Prefer not to say",
              ]),
            },
          ],
        },
        {
          title: "Citizenship",
          description: "",
          fields: [
            {
              name: "citizenship",
              label: "Your Citizenship",
              helperText:
                "U.S. citizenship is not a requirement for admission. Your response may prompt additional questions you'll need to answer.",
              type: "radio",
              options: optionize([
                "U.S. Citizen or U.S. National",
                "U.S. Dual Citizen",
                "U.S. Permanent Resident",
                "Not a U.S. Citizen",
              ]),
              isRequired: true,
            },
            {
              name: "birth_country",
              label: "Birth Country",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    notValue: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    notValue: "",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "primary_language",
              label: "What language is spoken at home?",
              type: "languageSearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "instruction_language",
              label: "What is your language of instruction in school?",
              type: "languageSearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "citizenship_country",
              label: "In which other country are you a citizen?",
              helperText: "Start typing to find and select a country.",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "visa_type",
              label: "Visa Type",
              type: "radio",
              options: optionize([
                "A-1",
                "A-2",
                "A-3",
                "B-2",
                "E-2",
                "F-1",
                "F-2",
                "G-1",
                "G-2",
                "G-3",
                "G-4",
                "H-4",
                "J-1",
                "J-2",
                "L-2",
                "R-2",
              ]),
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "TOEFL",
          show: {
            conditions: [
              {
                name: "citizenship",
                value: "Not a U.S. Citizen",
              },
            ],
          },
          fields: [
            {
              name: "toefl_ielts_considered",
              label: "Have you taken or will you take the TOEFL?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "toefl_ielts_date_taken",
              label: "TOEFL Date Taken or Date when TOEFL will be taken",
              type: "month",
              validations: {
                date: {
                  maxYearsInPast: 5,
                  maxYearsInFuture: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                  {
                    name: "toefl_ielts_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                  {
                    name: "toefl_ielts_considered",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "toefl_ielts_highest_score",
              label: "TOEFL Highest Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                  {
                    name: "toefl_ielts_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: false,
              validations: {
                value: {
                  min: 0,
                  max: 120,
                },
              },
            },
          ],
        },
        {
          title: "Your Contact Information",
          description: "Let us know how best to reach you and where you live.",
          fields: [
            {
              name: "email_address",
              label: "Email Address",
              type: "email",
              helperText:
                "This is the email address you provided when you started your application so it can't be changed.",
            },
            {
              name: "phone_number",
              label: "Phone Number",
              type: "tel",
              helperText:
                "Don't miss important updates! Add your cell. It's easy to opt-out later.",
            },
            {
              name: "country",
              label: "Country",
              type: "countrySearch",
              isRequired: true,
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "address1",
              label: "Address",
              type: "text",
              isRequired: true,
              group: 4,
              order: 1,
              width: "60%",
            },
            {
              name: "address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 4,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "city",
              label: "City",
              type: "text",
              group: 2,
              order: 1,
              isRequired: true,
            },
            {
              name: "state",
              label: "State",
              type: "stateSearch",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "zip",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 3,
              validations: {
                length: {
                  is: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "country",
                    value: "",
                  },
                  {
                    name: "country",
                    value: "United States",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "state_province",
              label: "State/Province",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: false,
            },
            {
              name: "postal_code",
              label: "Postal Code",
              type: "text",
              group: 2,
              order: 3,
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              show: {
                conditions: [
                  {
                    name: "country",
                    notValue: "",
                  },
                  {
                    name: "country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: false,
            },
          ],
        },
      ],
    },
    {
      id: "student-family",
      slug: "student-family",
      title: "Your Family",
      navTitle: "Family",
      description: "Help us get to know your family.",
      sections: [
        {
          title: "Your Parents & Guardians",
          description: "Tell us about your parents and/or guardians.",
          nestedSectionsMin: 1,
          nestedSectionsMax: 4,
          nestedSectionTitle: "Parent/Guardian {{x}}",
          nestedSectionItemName: "Parent/Guardian",
          controlSectionName: "parents",
          hide: {
            conditions: [
              {
                name: "parent_existence",
                value: "No",
              },
            ],
          },
          fields: [
            {
              name: "parent_existence",
              label: "Do you have parent and/or guardian?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "Yes",
                },
              ],
              hide: true,
            },
            {
              name: "parent_type",
              label: "Relationship",
              type: "radio",
              options: optionize([
                "Mother",
                "Father",
                "Legal Guardian",
                "Other",
              ]),
              isRequired: true,
            },
            {
              name: "parent_living",
              label: "Is parent/guardian living?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "parent_first_name",
              label: "First Name",
              type: "text",
              group: 1,
              order: 1,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_last_name",
              label: "Last Name",
              type: "text",
              group: 1,
              order: 2,
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_cell_phone",
              label: "Mobile Phone",
              helperText:
                "Most applicants add a parent cell so their family doesn't miss critical info on things like financial aid.",
              type: "tel",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_email_address",
              label: "Email Address",
              helperText:
                "We'd like to send your parent important updates about your application and about financial aid.",
              type: "email",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_same_address",
              label: "Is their address the same as yours?",
              type: "radio",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
              options: optionize(["Yes", "No"]),
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_country",
              label: "Country",
              type: "countrySearch",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "parent_address1",
              label: "Address",
              type: "text",
              group: 5,
              order: 1,
              width: "60%",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_address2",
              label: "Apartment, suite, etc.",
              type: "text",
              group: 5,
              order: 2,
              width: "40%",
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_city",
              label: "City",
              type: "text",
              group: 3,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_state",
              label: "State",
              type: "stateSearch",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              hide: {
                conditions: [
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
            },
            {
              name: "parent_state_province",
              label: "State/Province",
              type: "text",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                  {
                    name: "parent_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "parent_zip_code",
              label: "Zip Code",
              type: "zip_code",
              group: 3,
              order: 3,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                ],
              },
              hide: {
                conditions: [
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  is: 5,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_postal_code",
              label: "Postal Code",
              type: "text",
              group: 3,
              order: 3,
              show: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_same_address",
                    value: "No",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "parent_living",
                    value: "Yes",
                  },
                  {
                    name: "parent_country",
                    notValue: "United States",
                  },
                  {
                    name: "parent_country",
                    notValue: "",
                  },
                ],
              },
            },
            {
              name: "parent_occupation",
              label: "Occupation",
              type: "text",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
            {
              name: "parent_employer",
              label: "Employer",
              type: "text",
              hide: {
                conditions: [
                  {
                    name: "parent_living",
                    notValue: "Yes",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Alumni (Optional):",
          conditionalSection: true,
          description:
            "Has anyone else in your family attended Siena College? You can share up to 4 family members who attended or are currently enrolled.",
          fields: [
            {
              name: "alumni_shared",
              label:
                "Are any of your family members part of the Siena College community as alumni or current students?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: false,
            },
          ],
        },
        {
          title: "Alumni",
          nestedSectionsMax: 4,
          nestedSectionTitle: "Alumni {{x}}",
          nestedSectionItemName: "Alumni",
          controlSectionName: "alumnis",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "alumni_shared",
                value: "No",
              },
              {
                name: "alumni_shared",
                value: "",
              },
            ],
            logicalOr: true,
          },
          fields: [
            {
              name: "alumni_relationship",
              label: "Relationship",
              type: "select",
              options: optionize([
                "Parent",
                "Sibling",
                "Grandparent",
                "Aunt",
                "Uncle",
                "Cousin",
              ]),
            },
            {
              name: "alumni_full_name",
              label: "Full Name",
              type: "text",
            },
            {
              name: "alumni_is_enrolled",
              label: "Is this person currently enrolled at Siena?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
          ],
        },
      ],
    },
    {
      id: "your-experience",
      slug: "your-experience",
      title: "Your Experience",
      navTitle: "Your Experience",
      description: "We'd like to know about your accomplishments.",
      sections: [
        {
          title: "Most Recent Education",
          description: "Your Current or Most Recent High School",
          fields: [
            {
              name: "base_institution_ceeb",
              label: "Your School",
              helperText: "Start typing to find and select a school",
              type: "highSchoolSearch",
              isRequired: true,
            },
            {
              name: "base_institution_name",
              type: "text",
              label: "School Name",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_country",
              type: "countrySearch",
              label: "School Country",
              group: 1,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              // prefill: [
              //   {
              //     value: "United States",
              //     conditionSet: {
              //       conditions: [
              //         {
              //           name: "citizenship",
              //           value: "U.S. Dual Citizen",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Permanent Resident",
              //         },
              //         {
              //           name: "citizenship",
              //           value: "U.S. Citizen or U.S. National",
              //         },
              //       ],
              //       logicalOr: true,
              //     },
              //   },
              // ],
            },
            {
              name: "base_institution_state",
              type: "stateSearch",
              label: "School State",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_state_province",
              label: "School State/Province",
              type: "text",
              group: 1,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_city",
              type: "text",
              label: "School City",
              group: 2,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_zip_code",
              label: "Zip Code",
              type: "zip_code",
              group: 2,
              order: 2,
              validations: {
                length: {
                  is: 5,
                },
              },
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    value: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_postal_code",
              label: "Postal Code",
              type: "text",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
              validations: {
                length: {
                  min: 5,
                  max: 15,
                },
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                  {
                    name: "base_institution_country",
                    notValue: "United States",
                  },
                ],
              },
            },
            {
              name: "base_institution_address1",
              type: "text",
              label: "School Address",
              show: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_ceeb",
                    value: "I don't see my school",
                  },
                ],
              },
            },
            {
              name: "base_institution_from_date",
              label: "School Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "base_institution_to_date",
              label: "School End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
            {
              name: "base_institution_hs_did_you_graduate",
              label: "Have you or will you graduate?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "base_institution_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "base_institution_hs_did_you_graduate",
                    value: "No",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "base_institution_hs_did_you_graduate",
                    value: "No",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "Your Education History",
          conditionalSection: true,
          description:
            "If you've attended any other high schools/secondary schools or colleges/universities, please enter them below, including any college courses you completed while in high school.",
          fields: [
            {
              name: "institution_attended",
              label:
                "Have you attended other high schools/secondary schools or colleges/universities?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Additional Education History",
          nestedSectionsMax: 5,
          nestedSectionTitle: "Additional Education History {{x}}",
          controlSectionName: "institutions",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "institution_attended",
                value: "No",
              },
              {
                name: "institution_attended",
                value: "",
              },
            ],
            logicalOr: true,
          },
          fields: [
            {
              name: "institution_school_type",
              label: "School Type",
              type: "radio",
              options: optionize(["High School", "College"]),
              prefill: [
                {
                  value: "High School",
                },
              ],
              isRequired: true,
            },
            {
              name: "institution_ceeb_high_school",
              label: "Start typing to search for your school",
              type: "highSchoolSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_college",
              label: "Start typing to search for your college",
              type: "collegeSearch",
              isRequired: true,
              show: {
                conditions: [
                  {
                    name: "institution_school_type",
                    value: "College",
                  },
                ],
              },
            },
            {
              name: "institution_ceeb_name",
              type: "text",
              label: "Institution Name",
              show: {
                conditions: [
                  {
                    name: "institution_ceeb_high_school",
                    value: "I don't see my school",
                  },
                  {
                    name: "institution_ceeb_high_college",
                    value: "I don't see my school",
                  },
                ],
                logicalOr: true,
              },
              isRequired: true,
            },
            {
              name: "institution_from_date",
              label: "Institution Start Date",
              type: "month",
              group: 3,
              order: 1,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  isInPast: true,
                },
              },
              isRequired: true,
            },
            {
              name: "institution_to_date",
              label: "Institution End Date",
              type: "month",
              group: 3,
              order: 2,
              validations: {
                date: {
                  maxYearsInPast: 100,
                  maxYearsInFuture: 2,
                },
              },
            },
            {
              name: "institution_hs_equivalency_degree",
              label: "Will you receive a high school equivalency degree?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "institution_hs_did_you_graduate",
                    value: "No",
                  },
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "institution_hs_did_you_graduate",
                    value: "No",
                  },
                  {
                    name: "institution_school_type",
                    value: "High School",
                  },
                ],
              },
            },
          ],
        },
        {
          title: "High School Counselor",
          description:
            "Sharing this info makes it easier for your counselor to send us your grades and recommendation. Don't forget to tell them you applied to Siena!",
          fields: [
            {
              name: "counselor_first_name",
              label: "First Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 1,
            },
            {
              name: "counselor_last_name",
              label: "Last Name",
              type: "text",
              isRequired: true,
              group: 1,
              order: 2,
            },
            {
              name: "counselor_email_address",
              label: "Email Address",
              type: "email",
              isRequired: true,
            },
          ],
        },
        {
          title: "Letters of Recommendation Access Waiver",
          description: "",
          fields: [
            {
              name: "letters_of_rec_access_waiver",
              label: "Letters of Recommendation Access Waiver",
              helperText:
                "Letters of recommendation support your application and provide helpful information to the admissions office. Waiving your right to review these allows your teacher or counselor to provide their best assessment of you in terms of admission.",
              isRequired: true,
              type: "radio",
              options: optionize([
                "Yes, I waive my right to review",
                "No, I DO NOT waive my right to review",
              ]),
            },
          ],
        },
        {
          title: "High School Courses (Optional)",
          conditionalSection: true,
          description:
            "Tell us about the courses you are currently taking this academic year.",
          fields: [
            {
              name: "course_taken",
              label: "Are you currently taking high school courses?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Course",
          nestedSectionTitle: "Course {{x}}",
          nestedSectionItemName: "Course",
          nestedSectionsMax: 20,
          controlSectionName: "courses",
          isOptional: true,
          hide: {
            conditions: [
              {
                name: "course_taken",
                value: "No",
              },
              {
                name: "course_taken",
                value: "",
              },
            ],
            logicalOr: true,
          },
          fields: [
            {
              name: "course_title",
              type: "text",
              label: "Course Name",
            },
          ],
        },
        {
          title: "Test Scores (Optional):",
          description:
            "Let us know if you'd like us to consider your scores. Siena is test optional, so you don't have to submit them.",
          fields: [
            {
              name: "test_scores_considered",
              label: "Do you want to share test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "SAT",
          nestedSectionTitle: "SAT",
          nestedSectionItemName: "sat",
          nestedSectionsMax: 1,
          show: {
            conditions: [
              {
                name: "test_scores_considered",
                value: "Yes",
              },
            ],
          },
          fields: [
            {
              name: "sat_considered",
              label: "Do you want to provide SAT test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "sat_date_taken",
              label: "SAT Date Taken",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxYearsInPast: 5,
                  isInPast: true,
                },
              },
            },
            {
              name: "sat_highest_evidence_based_reading_and_writing_score",
              label: "Highest Evidence-Based Reading and Writing Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 200,
                  max: 800,
                  divisor: 10,
                },
              },
            },
            {
              name: "sat_highest_math_score",
              label: "Highest Math Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 200,
                  max: 800,
                  divisor: 10,
                },
              },
            },
            {
              name: "sat_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "sat_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "sat_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              group: 2,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "sat_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              group: 2,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_considered",
                    value: "Yes",
                  },
                  {
                    name: "sat_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
          ],
        },
        {
          title: "ACT",
          nestedSectionTitle: "ACT",
          nestedSectionItemName: "act",
          nestedSectionsMax: 1,
          show: {
            conditions: [
              {
                name: "test_scores_considered",
                value: "Yes",
              },
            ],
          },
          fields: [
            {
              name: "act_considered",
              label: "Do you want to provide ACT test scores?",
              type: "radio",
              options: optionize(["Yes", "No"]),
            },
            {
              name: "act_date_taken",
              label: "ACT Date Taken",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxYearsInPast: 5,
                  isInPast: true,
                },
              },
            },
            {
              name: "act_highest_composite_score",
              label: "ACT Highest Composite Score",
              type: "integer",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                value: {
                  min: 0,
                  max: 36,
                },
              },
            },
            {
              name: "act_future_tests",
              label: "Future Test Dates",
              type: "radio",
              options: optionize(["Yes", "No"]),
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "act_future_testing_date_1",
              label: "Future Testing Date 1",
              type: "month",
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "act_future_testing_date_2",
              label: "Future Testing Date 2",
              type: "month",
              group: 3,
              order: 1,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
            {
              name: "act_future_testing_date_3",
              label: "Future Testing Date 3",
              type: "month",
              group: 3,
              order: 2,
              show: {
                conditions: [
                  {
                    name: "test_scores_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_considered",
                    value: "Yes",
                  },
                  {
                    name: "act_future_tests",
                    value: "Yes",
                  },
                ],
              },
              validations: {
                date: {
                  maxMonthsInPast: 6,
                  maxYearsInFuture: 1,
                },
              },
            },
          ],
        },
        {
          title: "Legal Disclosure",
          description: "",
          fields: [
            {
              name: "misdemeanor_or_felony",
              label: "Have you ever been convicted of a misdemeanor or felony?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: true,
            },
            {
              name: "misdemeanor_or_felony_explanation",
              label: "Please provide a brief explanation.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "misdemeanor_or_felony",
                    value: "Yes",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: "Program Options",
      id: "program-options",
      slug: "program-options",
      description: "Help us get to know more about your college plans.",
      navTitle: "Program Options",
      sections: [
        {
          title: "Application Details",
          description:
            "Tell us about the type of application you would like to submit to Siena College.",
          fields: [
            {
              name: "transfer_app",
              label: "",
              type: "radio",
              options: optionize(["Yes", "No"]),
              prefill: [
                {
                  value: "No",
                },
              ],
              hide: true,
            },
            {
              name: "student_app_type",
              label: "Preferred Admission Plan",
              helperText:
                "Tell us how you're applying (for example, Early or Regular Decision). <a href='https://www.siena.edu/apply/deadlines/' target='_blank'>See deadlines and details for each plan</a>.",
              type: "radio",
              options: STANDARD_APP_OPTIONS,
              show: {
                conditions: [
                  {
                    name: "state",
                    notValue: "NY",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                ],
                logic: "0&&(1||2)",
              },
              isRequired: {
                conditions: [
                  {
                    name: "state",
                    notValue: "NY",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "student_app_type_heop",
              label: "Preferred Admission Plan",
              helperText:
                "Tell us how you're applying (for example, Early or Regular Decision). <a href='https://www.siena.edu/apply/deadlines/' target='_blank'>See deadlines and details for each plan</a>.",
              type: "radio",
              options: HEOP_ELIGIBLE_APP_OPTIONS,
              show: {
                conditions: [
                  {
                    name: "state",
                    value: "NY",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                ],
                logic: "0&&(1||2)",
              },
              isRequired: {
                conditions: [
                  {
                    name: "state",
                    value: "NY",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Citizen or U.S. National",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Dual Citizen",
                  },
                ],
                logic: "0&&(1||2)",
              },
            },
            {
              name: "student_app_type_non_citizen",
              label: "Preferred Admission Plan",
              helperText:
                "Tell us how you're applying (for example, Early or Regular Decision). <a href='https://www.siena.edu/apply/deadlines/' target='_blank'>See deadlines and details for each plan</a>.",
              type: "radio",
              options: NON_US_CITIZEN_APP_OPTIONS,
              show: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "",
                  },
                ],
                logicalOr: true,
              },
              isRequired: {
                conditions: [
                  {
                    name: "citizenship",
                    value: "Not a U.S. Citizen",
                  },
                  {
                    name: "citizenship",
                    value: "U.S. Permanent Resident",
                  },
                  {
                    name: "citizenship",
                    value: "",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "student_housing",
              label: "Student Housing",
              type: "radio",
              options: optionize(["On-Campus", "Commuter"]),
              isRequired: true,
            },
          ],
        },
        {
          title: "Academic Interests",
          description: "",
          fields: [
            {
              name: "intended_major",
              label: "Intended Major",
              helperText:
                "Let us know if you have a major in mind. If you don't yet, that's okay. You can select Undecided.",
              type: "select",
              options: SIENA_MAJORS,
              show: {
                conditions: [
                  {
                    name: "student_app_type",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "HEOP",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "intended_major_heop",
              label: "Intended Major",
              helperText:
                "Let us know if you have a major in mind. If you don't yet, that's okay. You can select Undecided.",
              type: "select",
              options: SIENA_MAJORS_HEOP,
              show: {
                conditions: [
                  {
                    name: "student_app_type_heop",
                    value: "HEOP",
                  },
                ],
              },
              isRequired: true,
            },
            {
              name: "intended_major_biology",
              label: "Intended Major",
              helperText:
                "All applicants of the Albany Medical College Program must select Biology as their intended major.",
              type: "select",
              isRequired: true,
              isReadOnly: true,
              options: [SIENA_BIOLOGY],
              show: {
                conditions: [
                  {
                    name: "student_app_type",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "AMCP",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "nursing_program",
              label: "",
              type: "radio",
              options: [],
              hide: true,
              prefill: [
                {
                  value: SIENA_NURSING.value,
                  override: true,
                  conditionSet: {
                    conditions: [
                      {
                        name: "intended_major",
                        value: SIENA_NURSING.value,
                      },
                    ],
                  },
                },
              ],
            },

            {
              name: "business_concentrations",
              label:
                "As a Business major, you may select up to 2 concentrations.",
              type: "info",
              value: "",
              show: {
                conditions: [
                  {
                    name: "intended_major",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "intended_major_heop",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "student_app_type",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "AMCP",
                  },
                ],
                logic: "(0||1||2||3||4||5)&&(6&&7)",
              },
            },
            {
              name: "concentration_1",
              info: "As a Business major, you may select up to 2 concentrations.",
              label: "First Concentration",
              type: "select",
              options: SIENA_BUSINESS_CONCENTRATIONS,
              show: {
                conditions: [
                  {
                    name: "intended_major",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "intended_major_heop",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "student_app_type",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "AMCP",
                  },
                ],
                logic: "(0||1||2||3||4||5)&&(6&&7)",
              },
            },
            {
              name: "concentration_2",
              label: "Second Concentration",
              type: "sienaBusinessConcentrationSelect",
              options: [],
              show: {
                conditions: [
                  {
                    name: "intended_major",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "intended_major_heop",
                    value: "BUSI - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Undecided - Business",
                  },
                  {
                    name: "intended_major_heop",
                    value: "UNBU - Exploring Business (Undecided)",
                  },
                  {
                    name: "student_app_type",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "AMCP",
                  },
                ],
                logic: "(0||1||2||3||4||5)&&(6&&7)",
              },
            },
            {
              name: "academic_interests",
              label:
                "Are you interested in learning about the following academic opportunities?",
              type: "checkbox",
              options: optionize(["Honors Program", "Pre-Law", "Pre-Medical"]),
            },
          ],
        },
        {
          title: "Essay",
          show: {
            conditions: [
              {
                name: "student_app_type",
                value: "AMCP",
              },
              {
                name: "student_app_type_heop",
                value: "AMCP",
              },
              {
                name: "student_app_type_heop",
                value: "HEOP",
              },
              {
                name: "intended_major",
                value: SIENA_NURSING.value,
              },
            ],
            logicalOr: true,
          },
          description:
            "An essay is required for your program. You can submit your essay now or send it to us later.",
          fields: [
            {
              name: "essay_prompt_amcp",
              label: "Essay Prompt",
              type: "radio",
              info: "Describe a personal service experience in your high school or community, what you gained from this experience and how it reflects the unique values of the Siena-Albany Medical College Program.",
              isRequired: true,
              options: [],
              prefill: [
                {
                  value: "Siena ACMP Essays",
                  conditionSet: {
                    conditions: [
                      {
                        name: "student_app_type",
                        value: "AMCP",
                      },
                      {
                        name: "student_app_type_heop",
                        value: "AMCP",
                      },
                    ],
                    logicalOr: true,
                  },
                },
              ],
              show: {
                conditions: [
                  {
                    name: "student_app_type",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "AMCP",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "essay_prompt_heop",
              label: "Essay Prompt",
              type: "radio",
              info: "Share an original essay on any topic of your choice to be considered for the HEOP Program. Graded papers are not accepted.",
              isRequired: true,
              options: [],
              prefill: [
                {
                  value: "Siena HEOP Essays",
                  conditionSet: {
                    conditions: [
                      {
                        name: "student_app_type",
                        value: "HEOP",
                      },
                      {
                        name: "student_app_type_heop",
                        value: "HEOP",
                      },
                    ],
                    logicalOr: true,
                  },
                },
              ],
              show: {
                conditions: [
                  {
                    name: "student_app_type",
                    value: "HEOP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "HEOP",
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "essay_prompt_nursing",
              label: "Essay Prompt",
              type: "radio",
              info: "Discuss activities, interests and/or volunteer experiences you have had in school or community organizations that will enhance your experience as a nurse.",
              isRequired: true,
              options: [],
              prefill: [
                {
                  value: "Siena Nursing Essays",
                  conditionSet: {
                    conditions: [
                      {
                        name: "intended_major",
                        value: SIENA_NURSING.value,
                      },
                    ],
                  },
                },
              ],
              show: {
                conditions: [
                  {
                    name: "intended_major",
                    value: SIENA_NURSING.value,
                  },
                  {
                    name: "student_app_type",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    notValue: "HEOP",
                  },
                ],
              },
            },
            {
              name: "essay_ready_to_submit",
              label: "Would you like to submit your essay now?",
              type: "radio",
              options: optionize(["Yes", "No"]),
              isRequired: {
                conditions: [
                  {
                    name: "student_app_type",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "HEOP",
                  },
                  {
                    name: "intended_major",
                    value: SIENA_NURSING.value,
                  },
                ],
                logicalOr: true,
              },
            },
            {
              name: "essay_inline_upload",
              label: "Do you have your essay in a file you'd like to upload?",
              type: "radio",
              options: optionize([
                "Yes, I have my essay written in a file.",
                "No, I'll write my essay here.",
              ]),
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                ],
              },
            },
            {
              name: "essay_file_upload",
              label: "Upload File",
              type: "file",
              show: {
                conditions: [
                  {
                    name: "essay_inline_upload",
                    value: "Yes, I have my essay written in a file.",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_inline_upload",
                    value: "Yes, I have my essay written in a file.",
                  },
                ],
              },
            },
            {
              name: "essay",
              label: "Type your essay here, if you did not upload one above.",
              type: "textarea",
              show: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "essay_inline_upload",
                    value: "No, I'll write my essay here.",
                  },
                ],
              },
              isRequired: {
                conditions: [
                  {
                    name: "essay_ready_to_submit",
                    value: "Yes",
                  },
                  {
                    name: "student_app_type",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "AMCP",
                  },
                  {
                    name: "student_app_type_heop",
                    value: "HEOP",
                  },
                  {
                    name: "intended_major",
                    value: SIENA_NURSING.value,
                  },
                ],
                logic: "0&&(1||2||3||4)",
              },
            },
          ],
        },
        {
          title: "Application Certification",
          fields: [
            {
              name: "certification_affirmed",
              isRequired: true,
              label:
                "Please affirm the following before you submit your application.",
              type: "checkbox",
              options: [
                {
                  label:
                    "I certify that all information submitted in the admission process - including this application and any other supporting materials - is my own work, factually true, and honestly presented, and that these documents will become the property of the institution to which I am applying and will not be returned to me. I understand that I may be subject to a range of possible disciplinary actions, including admission revocation, expulsion, or revocation of course credit, grades, and degree should the information I have certified be false.",
                  value: "Yes",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
