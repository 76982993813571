import { Option } from "types"

const EARLY_ACTION: Option = {
  label: "Early Action",
  value: "EA",
}

const EARLY_DECISION: Option = {
  label: "Early Decision <i>(Binding, must deposit by March 1)</i>",
  value: "ED",
}

const REGULAR_ADMISSION: Option = {
  label: "Regular Admission",
  value: "RA",
}

export const ALBANY_MEDICAL_COLLEGE_PROGRAM: Option = {
  label: "Albany Medical College Program",
  value: "AMCP",
}

export const HIGHER_EDUCATION_OPPORTUNITY_PROGRAM: Option = {
  label: "Higher Education Opportunity Program (HEOP)",
  value: "HEOP",
}

/**
 * For applicants who live in a U.S. state besides New York */
export const STANDARD_APP_OPTIONS = [
  EARLY_ACTION,
  EARLY_DECISION,
  REGULAR_ADMISSION,
  ALBANY_MEDICAL_COLLEGE_PROGRAM,
]

/**
 * New York residents only */
export const HEOP_ELIGIBLE_APP_OPTIONS = [
  ...STANDARD_APP_OPTIONS,
  HIGHER_EDUCATION_OPPORTUNITY_PROGRAM,
]

/**
 * Applications who don't live in the United States get `STANDARD_APP_OPTIONS` minus the Albany Medical College Program */
export const NON_US_CITIZEN_APP_OPTIONS = [
  ...STANDARD_APP_OPTIONS.filter(
    option => option.value !== ALBANY_MEDICAL_COLLEGE_PROGRAM.value,
  ),
]
