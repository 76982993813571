import { OptionData } from "types"

export const CREIGHTON_MAJORS: OptionData = [
  "Accounting",
  "Accounting - Accelerated Bachelor's to Master's Program",
  "American Studies",
  "Anthropology - Cultural",
  "Anthropology - Medical",
  "Art - Art History",
  "Art - Studio Art",
  "Biochemistry",
  "Biology",
  "Business Intelligence & Analytics - Business Analytics Track",
  "Business Intelligence & Analytics - Digital Media and Design Track",
  "Business Intelligence & Analytics - Information Technology Track",
  "Business Pre-Health Science Program",
  "Chemistry",
  "Classical and Near Eastern Civilizations",
  "Classical Languages - Greek & Latin",
  "Communication Studies",
  "Communication Studies/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
  "Communication Studies/Organizational Leadership Accelerated Bachelor's to Master's Program",
  "Computer Science",
  "Criminal Justice",
  "Data Science",
  "Economics - College of Art & Sciences",
  "Economics - Heider College of Business",
  "Education - Elementary",
  "Education - Elementary with Early Childhood Education Endorsement",
  "Education-Secondary Education Accelerated Bachelor's to Master's Program",
  "Engineering/Physics 3+2 Dual Degree Program",
  "Engineering/Chemistry 3+2 Dual Degree Program",
  "Engineering/Computer Science 3+2 Dual Degree Program",
  "Engineering/Math 3+2 Dual Degree Program",
  "English",
  "Envrionmental Science - Environmental Policy & Society Track",
  "Envrionmental Science - Global & Environmental Systems Track",
  "Envrionmental Science - Organismal/Population Ecology Track",
  "Exercise Science",
  "Finance & Technology",
  "Finance - Financial Analysis Track",
  "Finance - Financial Planning Track",
  "Finance - Financial Services Track",
  "Finance - Insurance & Risk Management",
  "Finance/Financial Planning and Psychology - Accelerated Bachelor’s to Master’s Program",
  "Finance/Investment Management and Financial Analysis Accelerated Bachelor’s to Master’s Program",
  "French & Francophone Studies",
  "German Studies",
  "Graphic Design & Film - Filmmaking",
  "Graphic Design & Film - Graphic Design Track",
  "Health Administration & Policy",
  "History",
  "International Business",
  "International Relations",
  "Journalism - Advertising Track",
  "Journalism - News Track",
  "Journalism - Public Relations Track",
  "Justice & Society",
  "Law - College of Arts and Sciences 3-3 Combined Degree Program",
  "Law - Heider College of Business 3-3 Combined Degree Program",
  "Law - Classical Civilizations 3-3 Combined Program",
  "Law - Classical Languages 3-3 Combined Degree Program",
  "Law - Communication Studies 3-3 Combined Degree Program",
  "Law - Criminal Justice 3-3 Combined Degree Program",
  "Law - English 3-3 Combined Degree Program",
  "Law - French Studies 3-3 Combined Degree Program",
  "Law - German Studies 3-3 Combined Degree Program",
  "Law - History 3-3 Combined Degree Program",
  "Law - Philosophy 3-3 Combined Degree Program",
  "Law - Political Science 3-3 Combined Degree Program",
  "Law - Psychology 3-3 Combined Degree Program",
  "Law - Spanish Studies 3-3 Combined Degree Program",
  "Law - Theology 3-3 Combined Degree Program",
  "Management - Business Ethics Track",
  "Management - Entrepreneurship Track",
  "Management - Human Resources Track",
  "Management - Pre-Law Track",
  "Management - Social Entrepreneurship Track",
  "Management/Integrative Health & Wellness Accelerated Bachelor's to Master's Program",
  "Management/Organizational Leadership Accelerated Bachelor's to Master's Program",
  "Marketing",
  "Marketing - Customer Analytics Specialization",
  "Marketing - Strategic Communications Specialization",
  "Marketing/Organizational Leadership Accelerated Bachelor's to Master's Program",
  "Master's Program to Paramedicine -  Accelerated Bachelor's to Master's Program",
  "Mathematics",
  "Mathematics - Medical Mathematics",
  "Music",
  "Musical Theatre",
  "Neuroscience",
  "Neuroscience - Accelerated Bachelor's to Master's Program",
  "Nursing",
  "Nursing - Traditional 4-year Program - Omaha",
  "Paramedicine",
  "Paramedicine - Accelerated Bachelor's to Master's Program",
  "Philosophy",
  "Philosophy - Ethics Specialization",
  "Physics",
  "Physics - Accelerated Bachelor's to Master's Program",
  "Physics - Applied Physics & Pre-Engineering",
  "Physics - Biomedical Physics Track",
  "Physics/Medical Physics - Accelerated Bachelor's to Master's Program",
  "Political Science - Legal Studies Track",
  "Political Science - Political Science Track",
  "Political Science - Public Policy Track",
  "Political Science - Research Design & Analysis Track",
  "Psychology",
  "Social Work",
  "Sociology",
  "Spanish and Hispanic Studies",
  "Sustainability",
  "Theatre - Performance Track",
  "Theatre - Technical Theatre Track",
  "Theology",
  "Theology - Biblical Studies Specialization",
  "Theology - Christian Life Studies Specialization",
  "Theology - Doctrinal, Historical, or Liturgical Specialization",
  "Undecided - College of Arts & Sciences",
  "Undecided - Heider College of Business",
]
